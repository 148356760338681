<template>
  <div v-if="showExportModal">
    <div class="sam-modal modal in show modal-active modal-open" :class="{'closing': modalIsClosing}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <SamH2 :title="title" noSpace />
            <button type="button" class="sam-modal-close close" v-on:click="closeModal()">
              <img src="../assets/images/close.svg" />
            </button>
          </div>
          <div class="modal-body">
            <slot />
            <div v-if="error" class="error-text">{{ error }}</div> <!-- Error text added here -->
            <div class="row sam-modal-footer">
              <div class="col-12">
                <SamPrimaryButton :text="closeButtonText" @buttonAction="closeModal" :alertButton="isAlert" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showExportModal" class="modal-backdrop show" :class="{'closing': modalIsClosing}"></div>
  </div>
</template>

<script>
import SamH2 from './SamH2.vue';
import SamPrimaryButton from './SamPrimaryButton.vue';

export default {
  name: "SamSimpleModal",
  components: {
    SamH2,
    SamPrimaryButton,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    closeButtonText: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    showExportModal: {
      type: Boolean,
      default: false
    },
    modalIsClosing: {
      type: Boolean,
      default: false
    },
    isAlert: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.error-text {
  color: red;
  margin-top: 10px;
}
</style>
