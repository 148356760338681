<template>
  <div>
    <span :class="['sam-span-link-label', marginBottomClass, 'mt-0']" v-if="text">
      <a :href="link" class="sam-link-value">{{ text }}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SamSpanLink',
  props: {
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '#'
    },
    marginBottomClass: {
      type: String,
      default: 'mb-1'
    }
  }
}
</script>

<style lang="scss" scoped>
.sam-span-link-label {
  display: inline-block;
}

.sam-link-value {
  color: #A7A7A7;
  text-decoration: underline;
}
</style>
