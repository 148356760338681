<template>
  <div>
    <span
        :class="['sam-label', marginBottomClass, 'mt-0', { 'mobile-truncate': truncateText && isMobile }]"
        :style="{ fontWeight: fontWeight }"
        v-if="truncatedText"
    >
      {{ truncatedText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SamSpan',
  props: {
    text: {
      type: String,
      default: ''
    },
    marginBottomClass: {
      type: String,
      default: 'mb-1'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    truncateText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    truncatedText() {
      if (this.truncateText && this.isMobile) {
        return this.text.length > 5 ? this.text.substring(0, 14) + "..." : this.text;
      }
      return this.text;
    }
  }
}
</script>

<style lang="scss" scoped>
.sam-label {
  // Other styles if needed
}
</style>
