<template>
  <div class="container pt-4 pb-4">
    <div class="row animated-container mt-0">
      <div class="col-12 col-md-6 mb-4">
        <img v-if="imageUrl" :src="imageUrl" class="w-100">
      </div>
      <div class="col-12 col-md-6 align-self-center">
        <div class="row">
          <div class="col-6">
            <SamLabel margin-bottom-class="mb-0" label="Offer ID"/>
            <SamSpan :text="projectId"/>
          </div>
        </div>
        <div class="original-content mt-3">
          <h1 class="mb-2" :style="headerStyles">Thank you for your feedback.</h1>
          <SamParagraph text="We hope we can work with you on another project."/>

          <div class="col-6 mt-1 pl-0 pr-0 mt-3">
            <SamLabel label="Framme contact details"/>
            <SamSpan :text="brmContactFirstName + ' ' + brmContactLastName"/>
            <SamSpanLink :text="brmContactEmail" :link="'mailto:' + brmContactEmail"/>
            <SamSpan :text="brmContactPhone"/>
            <SamSpanLink :text="brmWebsite" :link="'https://' + brmWebsite"/>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SamLabel from "@/components/SamLabel.vue";
import SamSpan from "@/components/table/SamSpan.vue";
import SamParagraph from "@/components/SamParagraph.vue";
import SamSpanLink from "@/components/SamSpanLink.vue";

export default {
  name: 'ThankYouDeclined',
  components: {SamSpanLink, SamParagraph, SamSpan, SamLabel},
  props: ['imageUrl', 'header', 'signature', 'headerStyles', 'projectId', 'brmContactFirstName', 'brmContactLastName', 'brmContactEmail', 'brmContactPhone', 'brmWebsite'],
  methods: {
    visitPage() {
      window.open('https://framme.com', '_blank');
    },
    sendEmail() {
      window.location.href = 'mailto:info@framme.com';
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.animated-container {
  padding-top: 0;
}

@media (max-width: 767.98px) {
  .animated-container {
    overflow-y: auto;
  }
}
</style>
