<template>
  <div v-if="showExportModal">
    <div class="sam-modal modal in show modal-active modal-open" :class="{'closing': modalIsClosing}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <SamH2 :title="title" noSpace />
            <button type="button" class="sam-modal-close close" v-on:click="closeModal()">
              <img src="../assets/images/close.svg" />
            </button>
          </div>
          <div class="modal-body">
            <slot />
            <div class="row sam-modal-footer">
              <div v-if="alignButtons" class="col-12 col-md-6 mb-4 mb-md-0">
                <SamPrimaryButton
                    v-if="!isSaveDisabled"
                    :text="saveButtonText"
                    @buttonAction="saveModal"
                    :alertButton="isAlert"
                    :disabled="isSaveDisabled"
                    customClass="mb-0 mb-md-4"
                />
              </div>
              <div v-if="alignButtons" :class="['col-12', isSaveDisabled ? 'col-md-12' : 'col-md-6', 'm-0']">
                <SamSecondaryButton text="Close" @buttonAction="closeModal" />
              </div>
              <div v-else class="col-12">
                <SamPrimaryButton
                    v-if="!isSaveHidden"
                    :text="saveButtonText"
                    @buttonAction="saveModal"
                    :alertButton="isAlert"
                    :disabled="isSaveDisabled"
                />
                <SamSecondaryButton text="Close" @buttonAction="closeModal" class="m-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showExportModal" class="modal-backdrop show" :class="{'closing': modalIsClosing}"></div>
  </div>
</template>

<script>
import SamH2 from './SamH2.vue';
import SamPrimaryButton from './SamPrimaryButton.vue';
import SamSecondaryButton from './SamSecondaryButton.vue';

export default {
  name: "SamModal",
  components: {
    SamH2,
    SamPrimaryButton,
    SamSecondaryButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    showExportModal: {
      type: Boolean,
      default: false
    },
    modalIsClosing: {
      type: Boolean,
      default: false
    },
    saveButtonText: {
      type: String,
      default: 'Save'
    },
    isAlert: {
      type: Boolean,
      default: false
    },
    alignButtons: {
      type: Boolean,
      default: false
    },
    isSaveDisabled: {
      type: Boolean,
      default: false
    },
    isSaveHidden: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    saveModal() {
      if (!this.isSaveDisabled) {
        this.$emit('save');
      }
    }
  }
}
</script>

<style scoped>
</style>
