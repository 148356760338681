<template>
  <div v-if="shouldShowError" class="sam-branding-input-error">
    Branding is required.
  </div>
</template>

<script>
export default {
  name: 'BrandingCombinationError',
  props: {
    validationObject: {
      type: Object,
      required: true
    },
    productId: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    shouldShowError() {
      return (
          this.validationObject[this.productId] &&
          !this.validationObject[this.productId].isNotEmpty &&
          !this.validationObject[this.productId].$pending
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.sam-branding-input-error {
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $alertRed;
  font-family: $boldFontStyle;
}
</style>
