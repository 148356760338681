<template>
  <div v-if="showExportModal">
    <div class="sam-modal modal in show modal-active modal-open" :class="{'closing': modalIsClosing}">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <SamH2 :title="title" noSpace />
            <button type="button" class="sam-modal-close close" v-on:click="closeModal()">
              <img src="../../../assets/images/close.svg" />
            </button>
          </div>
          <div class="modal-body pb-3">
            <slot />
            <div v-if="error" class="error-text">{{ error }}</div>

          </div>
        </div>
      </div>
    </div>
    <div v-if="showExportModal" class="modal-backdrop show" :class="{'closing': modalIsClosing}"></div>
  </div>
</template>


<script>



import SamH2 from "@/components/SamH2.vue";


export default {
  name: "SamMockupModal",
  components: {
    SamH2,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    closeButtonText: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    showExportModal: {
      type: Boolean,
      default: false
    },
    modalIsClosing: {
      type: Boolean,
      default: false
    },
    isAlert: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.custom-modal-xl {
  max-width: 95%;
}

.error-text {
  color: red;
  margin-top: 10px;
}
</style>
