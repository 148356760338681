<template>
  <div class="container pt-4 pb-4">
    <div class="row animated-container mt-0">
      <div class="col-12 col-md-6 mb-4">
        <img v-if="imageUrl" :src="imageUrl" class="w-100">
      </div>
      <div class="col-12 col-md-6 align-self-center">
        <div class="row">
          <div class="col-6">
            <SamLabel margin-bottom-class="mb-0"  label="Offer ID"/>
            <SamSpan :text="projectId"/>
          </div>
        </div>
        <div class="original-content mt-3">
          <h1  class="mb-2" :style="headerStyles">This offer page has expired!</h1>
          <SamParagraph text="This order has already been approved or declined or the offer has expired. If you have any questions or want to get another offer, please contact us via email or visit framme.com to send a new enquiry."/>

          <div class="row mt-4">
            <div class="col-6 col-md-6 mt-2">
              <SamPrimaryButton text="Visit Framme.com" @buttonAction="visitPage" />
            </div>
            <div class="col-6 col-md-6 mt-2">
              <SamSecondaryButton text="Contact Us via Email" @buttonAction="sendEmail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SamLabel from "@/components/SamLabel.vue";
import SamSpan from "@/components/table/SamSpan.vue";
import SamPrimaryButton from "@/components/SamPrimaryButton.vue";
import SamParagraph from "@/components/SamParagraph.vue";
import SamSecondaryButton from "@/components/SamSecondaryButton.vue";

export default {
  name: 'OfferRejected',
  components: {SamSecondaryButton, SamParagraph, SamPrimaryButton, SamSpan, SamLabel},
  props: ['imageUrl', 'header', 'signature', 'headerStyles', 'projectId'],
  methods: {
    visitPage() {
      window.open('https://framme.com', '_blank');
    },
    sendEmail() {
      window.location.href = 'mailto:info@framme.com';
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.animated-container {
  padding-top: 0;
}

@media (max-width: 767.98px) {
  .animated-container {
    overflow-y: auto;
  }
}
</style>
