<template>
  <h2 class="text-start title" :class="{'mb-0': noSpace}">
   {{title}}
  </h2>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    noSpace: {
      type: Boolean,
      default: false
    }
  },
  name: 'SamH2'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .title {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    letter-spacing: $letter-spacing-base;
    margin-bottom: $textMarginBottom;
    font-family: $boldFontStyle;
  }
</style>
